<template>
  <div class="container-fluid w-75" v-if="planReport">
      <div class="text-right mb-2">
          <button class="btn btn-info" @click="printReport">印刷</button>
      </div>
      <CCard id="printable">
          <CCardBody class="print">
              <div class="row">
                  <div class="col-4"></div>
                  <div class="col-4">
                      <h3 class="text-center h5">訪問看護計画書</h3>
                  </div>
                  <div class="col-4">
                      <p class="pt-2 h5">施設名：{{ person.relationships.facility.attributes.name }}</p>
                  </div>
              </div>
              <div class="row">
                  <div class="col-12">
                      <table class="table table-bordered h5">
                          <tbody>
                          <tr>
                              <td colspan="1" class="label">利用者氏名</td>
                              <td colspan="5">{{ person.attributes.name }}様</td>
                          </tr>
                          <tr>
                              <td colspan="1" class="label">生年月日</td>
                              <td colspan="2">
                                  {{ person.attributes.birthday | moment('YYYY年MM月DD日')  }}
                              </td>
                              <td colspan="1" class="label">年齢</td>
                              <td colspan="2">{{ getAge(person.attributes.birthday) }}歳</td>
                          </tr>
                          <tr>
                              <td colspan="1" class="label">要介護・障害認定の状況</td>
                              <td colspan="5">{{ person.attributes.remark }}</td>
                          </tr>
                          <tr>
                              <td colspan="1" class="label">住所</td>
                              <td colspan="5">{{ person.attributes.address }}</td>
                          </tr>
                          <tr>
                              <td colspan="1" class="label">施設内長期目標</td>
                              <td colspan="5">{{ planReport.attributes.long_term_aim }}</td>
                          </tr>
                          <tr>
                              <td colspan="1" class="label">施設内短期目標</td>
                              <td colspan="5">{{ planReport.attributes.short_term_aim }}</td>
                          </tr>
                          <tr>
                              <td colspan="1" rowspan="5" class="label">Dr指示内容</td>
                              <td colspan="5">① {{ planReport.attributes.instruction1 }}</td>
                          </tr>
                          <tr>
                              <td colspan="5">② {{ planReport.attributes.instruction2 }}</td>
                          </tr>
                          <tr>
                              <td colspan="5">③ {{ planReport.attributes.instruction3 }}</td>
                          </tr>
                          <tr>
                              <td colspan="5">④ {{ planReport.attributes.instruction4 }}</td>
                          </tr>
                          <tr>
                              <td colspan="5" v-if="planReport.attributes.instruction5_choice !== 3">
                                  ⑤ 必要回数： {{ getRequiredNumber(planReport.attributes.instruction5_choice) }}
                              </td>
                              <td colspan="5" v-else>
                                  ⑤ 必要回数： {{ planReport.attributes.instruction5 }}
                              </td>
                          </tr>
                          <tr>
                              <td colspan="1" class="label">訪問看護の目標</td>
                              <td colspan="5">{{ planReport.attributes.care_aim }}</td>
                          </tr>
                          <tr>
                              <td colspan="1" class="label">年月日</td>
                              <td colspan="3" class="label">問題点・解決策</td>
                              <td colspan="2" class="label">評価</td>
                          </tr>
                          <tr v-for="detail in planReport.relationships.planReportDetails" :key="detail.id">
                              <td colspan="1" class="h6">{{ detail.attributes.date | moment('YYYY年MM月DD日') }}</td>
                              <td colspan="3">{{ detail.attributes.description }}</td>
                              <td colspan="2">{{ detail.attributes.evaluation }}</td>
                          </tr>
                          <template v-if="planReport.relationships.planReportDetails.length < 5">
                              <tr v-for="index in (5 - planReport.relationships.planReportDetails.length)" :key="index">
                                  <td colspan="1"></td>
                                  <td colspan="3"></td>
                                  <td colspan="2"></td>
                              </tr>
                          </template>
                          <tr>
                              <td colspan="1" class="label h6">衛生材料等が必要な<br>処置の有無</td>
                              <td colspan="5" class="label">
                                  <span class="is-required">
                                      <span v-if="planReport.attributes.required_medical_goods === 1">✔︎ </span>
                                      有
                                  </span>
                                  <span class="not-required">
                                      <span v-if="planReport.attributes.required_medical_goods === 0">✔︎ </span>
                                      無
                                  </span>
                              </td>
                          </tr>
                          <tr>
                              <td colspan="2" class="label">処置の内容</td>
                              <td colspan="2" class="label">衛生材料（種類・サイズ）等</td>
                              <td colspan="2" class="label">必要量</td>
                          </tr>
                          <tr>
                              <td colspan="2">{{ planReport.attributes.treatment }}</td>
                              <td colspan="2">{{ planReport.attributes.medical_goods }}</td>
                              <td colspan="2">{{ planReport.attributes.required_amount }}</td>
                          </tr>
                          <tr>
                              <td colspan="1" class="label">備考</td>
                              <td colspan="5">{{ planReport.attributes.remark }}</td>
                          </tr>
                          <tr>
                              <td colspan="1" class="label">作成者</td>
                              <td colspan="3">氏名：{{ $root.loginUser.attributes.name }}</td>
                              <td colspan="2">
                                  <span class="d-flex justify-content-between">
                                      <span class="nurse">
                                          <span v-if="$root.loginUser.attributes.nurse === 0">✔︎ </span>
                                          看護師
                                      </span>
                                      <span class="health-practitioner">
                                          <span v-if="$root.loginUser.attributes.nurse === 1">✔︎ </span>
                                          保健師
                                      </span>
                                  </span>
                              </td>
                          </tr>
                          </tbody>
                      </table>
                  </div>
              </div>
              <div class="row">
                  <div class="col-12 h5">
                      <p>上記の訪問看護計画書に基づき指定訪問看護又は看護サービスの提供を実施いたします。</p>
                  </div>
              </div>
              <div class="row">
                  <div class="col-12 h5">
                      <p class="text-right" v-if="planReport.attributes.regist_date">
                          {{ planReport.attributes.regist_date | moment('YYYY年MM月DD日') }}
                      </p>
                      <div class="d-flex float-left" v-else>
                          <span class="ml-4">年</span><span class="ml-4">月</span><span class="ml-4">日</span>
                      </div>
                  </div>
              </div>
              <div class="row">
                  <div class="col-12 h5">
                      <h5 class="p-3" v-if="planReport.attributes.clinic_name">
                          {{ planReport.attributes.clinic_name }}殿
                      </h5>
                      <h5 class="p-3" style="margin-left: 8.5rem" v-else>殿</h5>
                  </div>
              </div>
              <div class="row">
                  <div class="col-6"></div>
                  <div class="col-6 h5">
                      <p class="border-bottom">事業所名：{{ office.attributes.name }}</p>
                  </div>
              </div>
              <div class="row">
                  <div class="col-6"></div>
                  <div class="col-6 h5">
                      <p class="border-bottom">管理者氏名：{{ office.attributes.manager_name }}</p>
                  </div>
              </div>
          </CCardBody>
      </CCard>
  </div>
</template>

<script>
import ApiRouter from "@/components/Systems/ApiRouter";
import ErrorHandler from "@/components/Systems/ErrorHandler";
import Helpers from "@/components/Systems/Helpers";

export default {
    name: "DisplayHomeNursingPlan",
    mixins: [ApiRouter, ErrorHandler, Helpers],
    data: function () {
        return {
            planReport: null,
            person: null,
            facility: null,
            office: null
        }
    },
    created: function () {
        this.loadPlanReport();
    },
    methods: {
        loadPlanReport: function () {
            axios.get(this.routes.planReport(this.$route.params['id']))
                .then(response => {
                    this.planReport = response.data.data;
                    this.person = this.planReport.relationships.person;
                    this.facility = this.person.relationships.facility;
                    this.office = this.facility.relationships.office;
                })
                .catch(error => {
                    this.handleErrorStatusCode(error);
                });
        },
        getRequiredNumber: function (instruction5Choice) {
            switch (instruction5Choice) {
                case 0:
                    return '週1回';
                case 1:
                    return '週2回';
                case 2:
                    return '週3回以上';
                default:
                    return '';
            }
        },
        printReport: function () {
            window.document.body.innerHTML = document.getElementById('printable').innerHTML;
            window.print();
            this.$router.go({path: this.$router.currentRoute.path, force: true});
        }
    }
}
</script>

<style src="./DisplayHomeNursingPlan.scss" lang="scss" scoped/>
